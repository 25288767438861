<template>
  <v-container>
    <v-row justify="center" v-if="prizes">
      <v-col
        v-for="(prize, index) in prizes"
        :key="prize.id + index"
        cols="12"
        sm="10"
        md="6"
        lg="4"
        xl="3"
      >
        <ItemPrizesHistory :prize="prize" class="pa-2" />
      </v-col>
    </v-row>
    <h3 v-else class="red--text text-center">
      {{ $t("prizesNotFound") }}
    </h3>
  </v-container>
</template>

<script>
export default {
  name: "ListPrizesComponent",
  components: {
    ItemPrizesHistory: () => import("./ItemPrizesHistory.vue"),
  },
  props: {
    prizes: { type: Array, required: true },
  },
  data() {
    return {
      prizeSelected: {},
    };
  },
  methods: {
    openDialog(prizeSelected, handleEventOpenDialog) {
      this.prizeSelected = prizeSelected;
      handleEventOpenDialog();
    },
    emitToContainerPrizeRedeemed() {
      this.$emit("prizeRedeemed");
    },
  },
};
</script>
